import moment from "moment";
import { PERMISSIONS, USER_GROUPS } from "./constants"
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { saveAs } from "file-saver";


export const userGroups = () => {
    const groups = localStorage.getItem(USER_GROUPS)!==null?JSON.parse(localStorage.getItem(USER_GROUPS)):[]
    return groups
}

export const hasGroup = (group) => {
    const groups = userGroups()
    if (groups.includes(group)) {
        return true
    }
    return false
}
export const hasPermission=(permission)=>{
    if(localStorage.getItem(PERMISSIONS)!==null){
        const permissions = localStorage.getItem(PERMISSIONS).split(',');
        return permissions.includes(permission);
    }
    return false
}
export function arrayRemoveItem(arr, value) {
    return arr.filter(ele=> 
        // console.log('ele',ele,'value',value)
         ele !== value
    );
}

export const getBuildDate = (epoch) => {
    const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
    return buildDate;
};
export function capitalize(string) {
    if (string !== undefined) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return ''
}

export function capitalizeFirstLetter(string="") {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uniqueElements(array){
    return array.filter((v, i, a) => a.indexOf(v) === i);
}

export const isEmailOrPhone=(val)=>{
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
    let result = null;
    if(emailRegex.test(val)){
        result= 'EMAIL'
    }
    else if(phoneRegex.test(val)){
        result= 'PHONE'
    }
    return result;
}

export const getThemedLogo = (themeMode) => themeMode === 'light' ? "/static/vo.png" : "/static/vo-2.png"
export const getThemedIconifyLogo = (themeMode) => themeMode === 'light' ? "tabler:badge-vo-filled" : "tabler:badge-vo-filled"

export function removeGarbageChar(fileName) {
    const arr = fileName.split(".");
    const ext = arr.pop();
    const name = arr.join(".");
    const filterData = name.replace(/[^a-zA-Z0-9-_]/g, '');
    return `${filterData}.${ext}`
}

export async function playAudio() {
    const audio = new Audio('https://www2.cs.uic.edu/~i101/SoundFiles/StarWars60.wav');
    // let file = new File('../assets/sounds/ding.mp3')
    // var audio = new Audio(ding);
    audio.type = 'audio/wav';

    try {
        await audio.play();
        console.log('Playing...');
    } catch (err) {
        console.log(err);
    }
}