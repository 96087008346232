import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    config: {
        themeMode: 'light',
        drawer_width: 280,
        isDrawerOpen: true,
        fontSize: "Medium"
    },
    status: 'idle',
    error: ''
}

export const domSlice = createSlice({
    name: 'domConfigs',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.config.themeMode = ['dark', 'light'].includes(action.payload) ? action.payload : 'light';
        },
        toggleDrawer: (state) => {
            // console.log('toggling drawer');
            state.config.isDrawerOpen = !state.config.isDrawerOpen;
        },
        setFontSize: (state, action) => {
            state.config.fontSize = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setTheme, toggleDrawer, setFontSize } = domSlice.actions
export default domSlice.reducer
