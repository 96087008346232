// ----------------------------------------------------------------------

import { alpha } from "@mui/material";

export default function ListItem(theme) {
    return {
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        // bgcolor: alpha(theme.palette.action.selected, theme.palette.action.hoverOpacity),
                        // boxShadow: 'none',
                        backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.hoverOpacity)
                        // color: 'white'
                    },
                    '&.Mui-selected':{
                        backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity)
                    }
                },
            },
        },
    };
}
