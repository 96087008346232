import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import { useSelector } from 'react-redux';
import { palette } from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
    children: PropTypes.node,
    themeMode: PropTypes.string
};

export default function ThemeProvider({ children, themeMode }) {

    const fontSize = useSelector(state => state.dom.config.fontSize)

    const getTypographyVariant = (fontSize) => {
        switch (fontSize) {
            case 'small':
                return 12; // Example size, adjust as needed
            case 'large':
                return 16; // Example size, adjust as needed
            default:
                return 14; // Default size for 'medium'
        }
    };
    const themeMemoOptions = useMemo(
        () => ({
            palette: palette(themeMode),
            shape: { borderRadius: 8 },
            typography: {
                ...typography,
                fontSize: getTypographyVariant(fontSize), // This adjusts the base font size
            },
            shadows: shadows(themeMode),
            customShadows: customShadows(themeMode),
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 900,
                    lg: 1200,
                    xl: 1536,
                },
            },
        }),
        [fontSize, themeMode]
    );

    const theme = createTheme(themeMemoOptions);
    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
}
