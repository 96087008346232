// // ----------------------------------------------------------------------

export default function TextField(theme) {
    return {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    input: {
                        '&::placeholder': { // Default state
                            color: theme.palette.text.secondary, // Replace with your normal placeholder color
                        },
                        '&.Mui-focused::placeholder': { // Focused state
                            color: theme.palette.text.secondary, // Replace with your focused placeholder color
                        },
                    },
                    "*::-webkit-scrollbar": {
                        height: "4px",
                        width: "5px"
                    },
                    "*::-webkit-scrollbar-track": {
                        background: "#E4EFEF"
                    },
                    "*::-webkit-scrollbar-thumb": {
                        background: "#1D388F61",
                        borderRadius: "2px"
                    }
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    color: theme.palette.text.secondary,
                    '&.Mui-focused': {
                        color: theme.palette.text.secondary,
                    }
                },
            },
        },
    };
}

