import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------

function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
    lighter: '#ffffff',
    light: '#ffffff',
    // main: '#2065D1', real theme color
    main: '#212B36',
    dark: '#28282B',
    darker: '#061B64',
    contrastText: '#fff',
};

const SECONDARY = {
    lighter: '#f2e9ed',
    light: '#e7428b',
    // main: '#3366FF', real theme secondary color
    main: '#170636',
    dark: '#9e0d4d',
    darker: '#091A7A',
    contrastText: '#fff',
};

const INFO = {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#04297A',
    contrastText: '#fff',
};

const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
    contrastText: GREY[800],
};

const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: GREY[800],
};

const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#fff',
};

const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
    violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
    blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
    green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
    yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
    red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};
const VO_COLORS = {
    main: "#00aae5"
}

export const palette = (themeMode) => {
    const PALETTE_MODE = themeMode;
    return {
        common: { black: '#000', white: '#fff' },
        primary: { ...PRIMARY },
        secondary: { ...SECONDARY },
        info: { ...INFO },
        success: { ...SUCCESS },
        warning: { ...WARNING },
        error: { ...ERROR },
        grey: GREY,
        gradients: GRADIENTS,
        chart: CHART_COLORS,
        divider: GREY[500_24],
        vo: { ...VO_COLORS },
        text: { primary: PALETTE_MODE === "light" ? GREY[800] : "#fff", secondary: PALETTE_MODE === "light" ? GREY[600] : "rgba(255, 255, 255, 0.7)", disabled: PALETTE_MODE === "light" ? GREY[500] : "rgba(255, 255, 255, 0.5)", invert: PALETTE_MODE === "dark" ? GREY[800] : "#fff" },
        background: { paper: PALETTE_MODE === "light" ? '#fff' : '#28282B', default: PALETTE_MODE === "light" ? GREY[100] : '#28282B', invert: PALETTE_MODE === "dark" ? GREY[100] : '#28282B', neutral: GREY[200] },
        border: {
            primary: PALETTE_MODE === "light" ? GREY[300] : "rgba(255, 255, 255, 0.16)"
        },
        action: {
            active: PALETTE_MODE === "light" ? GREY[500] : '#fff',
            hover: PALETTE_MODE === "light" ? GREY[500_8] : "rgba(255, 255, 255, 0.08)",
            selected: PALETTE_MODE === "light" ? GREY[500_16] : 'rgba(255, 255, 255, 0.16)',
            disabled: PALETTE_MODE === "light" ? GREY[500_80] : 'rgba(255, 255, 255, 0.3)',
            disabledBackground: PALETTE_MODE === "light" ? GREY[500_24] : 'rgba(255, 255, 255, 0.12)',
            focus: GREY[500_24],
            hoverOpacity: 0.08,
            disabledOpacity: 0.48,
            // selectedOpacity: 0.68,
            selectedTopic: PALETTE_MODE === "light" ? PRIMARY.lighter : PRIMARY.darker,
        },
    }
};
