import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { configureStore, combineReducers } from "@reduxjs/toolkit";

import AuthSlice from "./slices/AuthSlice";
import DomSlice from "./slices/DomSlice";
import UserSlice from "./slices/UserSlice";
import ProjectSlice from './slices/ProjectSlice';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ["users","projects"]
}

const rootReducer = combineReducers({
    user: AuthSlice,
    dom: DomSlice,
    users: UserSlice,
    projects: ProjectSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export const persistedStore = persistStore(store)

