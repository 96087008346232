// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#e0e0e0',
          color: 'black',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedError: {
          color: theme.palette.error.main,
          // backgroundColor: theme.palette.secondary.dark,
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        // outlined:{
        //     border: '2px solid rgba(118, 108, 110, 0.5)',
        // },
        outlinedError: {
          color: theme.palette.secondary.dark,
          // borderColor: theme.palette.secondary.dark
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
