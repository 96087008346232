// ----------------------------------------------------------------------

export default function Card(theme) {
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: theme.shadows[2],
                    borderRadius: Number(theme.shape.borderRadius) * 2,
                    position: 'relative',
                    zIndex: 0, // Fix Safari overflow: hidden with border radius
                },
                linearBg:{
                    background:'linear-gradient(to bottom, #d6f9ff 0%,#9ee8fa 100%);'
                }
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: { variant: 'h6' },
                subheaderTypographyProps: { variant: 'body2' },
            },
            styleOverrides: {
                root: {
                    padding: theme.spacing(3, 3, 0),
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(3),
                    "&::-webkit-scrollbar": {
                        height: "4px",
                        width: "5px"
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#E4EFEF"
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "#1D388F61",
                        borderRadius: "2px"
                    }
                },
            },
        },
    };
}
