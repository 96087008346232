
const path = {
    home: '/',
    userGuide: '/userguide',
    signin: '/auth/signin',
    forgetPassword: '/auth/forget-password',
    resetPassword: '/auth/reset-password',
    calender_auth: '/auth/add-event-to-calender/',
    signout: '/auth/authentication/global-logout',
    signup: '/auth/signup',
    join_meeting: '/join/',
    meeting_list: '/my-meetings',
    meeting_details: '/meeting-details/',
    drawing: '/drawing/',
    error_404: '/404'
};


export const wsUrl = () => ({
        'DMA': {
            dev_local: `http://127.0.0.1:8000`,
            dev: `https://vo.dma-bd.com`,
            qa: `https://vo.dma-bd.com`,
            stage: `https://vo.dma-bd.com`,
        },
    }[process.env.REACT_APP_COMPANY_NAME][process.env.REACT_APP_ENV_MODE || 'dev'])


export const apiUrl = () => ({
        'DMA': {
            dev_local: "http://localhost:8000/admin/",
            dev: "https://vo.clouddma.com/admin/",
            qa: "https://vo.dma-bd.com/",
            stage: "https://vo.dma-bd.com/",
        },
    }[process.env.REACT_APP_COMPANY_NAME][process.env.REACT_APP_ENV_MODE || 'dev'])


export default path;
