import {
    Stack,
    Button,
    Container,
    Typography,
    Box, Input, InputAdornment,
} from '@mui/material';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import Iconify from '../../components/Iconify';
import LoaderDialog from '../../components/LoaderDialog';
import Page from '../../components/Page';

export default function UserList() {
    const [searchText, setSearchText] = useState()
    const [customers, setCustomers] = useState([])
    // op codes
    const [showLoaderDialog] = useState(false)

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            setCustomers(customers.filter((item) => String(item.card_number).includes(searchText)))
        }
    }

    const columns = [
        { field: 'id', headerName: '#', width: 10 },
        {
            field: 'card_number', headerName: 'Card Number', width: 230,
            renderCell: (params) => <Typography variant='span' sx={{ fontWeight: 'bold' }}>{params.row.card_number}</Typography>
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 230,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        { field: 'email', headerName: 'Email', width: 200 },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 130,
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const {api} = params;
                    const thisRow = {};

                    api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach(
                        (c) => { thisRow[c.field] = params.getValue(params.id, c.field) }
                    );

                    // eslint-disable-next-line no-alert
                    return alert(JSON.stringify(thisRow, null, 4));
                };

                return <Button disabled variant='contained' onClick={onClick}>View</Button>;
            }
        },
    ];

    return (
        <>
            <Page title="Employees">
                <LoaderDialog open={showLoaderDialog} url={null} />
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Employees
                        </Typography>
                        <Button variant="contained" component={RouterLink} to="/dashboard/employees/add" startIcon={<Iconify icon="eva:plus-fill" />}>
                            New Employee
                        </Button>
                    </Stack>

                    <Box>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Input
                                autoFocus
                                fullWidth
                                disableUnderline
                                placeholder="Search…"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyPress={handleKeyPress}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                    </InputAdornment>
                                }
                                sx={{ mr: 1, fontWeight: 'fontWeightBold', borderBottom: 1, borderRadius: 1, borderColor: 'grey.500' }}
                            />
                            <Button variant="contained" onClick={() => { }}>
                                Search
                            </Button>
                        </Stack>
                        <DataGrid
                            autoHeight {...customers}
                            disableSelectionOnClick
                            rows={customers}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            density="comfortable"
                            // checkboxSelection
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            onRowClick={(rowData) => { console.log(rowData.row) }}
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                                    outline: "none !important",
                                },
                            }}
                        />
                    </Box>
                </Container>
            </Page>
        </>
    );
}
