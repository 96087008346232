import axios from "axios";

import { TOKEN, REFRESH_TOKEN, API_PATHS } from "../helper/constants";
import siteConfig from "../helper/siteConfig";

export const API = axios.create({
    baseURL: siteConfig.base_urls.api,
    timeout: 300000,
    headers: {
        "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`,
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*"
    }
})
export const FILE_API = axios.create({
    baseURL: siteConfig.base_urls.api,
    timeout: 300000,
    headers: {
        "Authorization": `Bearer ${localStorage.getItem(TOKEN)}`,
        "Content-Type": "multipart/form-data",
        // "Access-Control-Allow-Origin": "*"
    }
})

API.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN)}`
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// axios interceptor for refreshing access/id token
API.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalConfig = error.config;
        if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalConfig?._retry) {
            originalConfig._retry = true;
            try {
                const updateTokenRes = await PUBLIC_API.post(`${API_PATHS.REFRESH_TOKEN}`, { refresh: localStorage.getItem(REFRESH_TOKEN) }, { headers: { Authorization: `Bearer ${localStorage.getItem(REFRESH_TOKEN)}` } });
                const { access } = updateTokenRes.data;
                localStorage.setItem(TOKEN, access);
                error.response.config.headers.Authorization = `Bearer ${access}`;
                return API(error.response.config);
            } catch (_error) {
                console.log(_error);
                if (error.response.status === 401 || error.response.status === 400) {
                    localStorage.clear();
                    window.location.replace('/');
                }
                return Promise.reject(_error);
            }
        }
        return Promise.reject(error);
    }
);

FILE_API.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN)}`
        }
        return config;
    },
    (error) => Promise.reject(error)
);
export const PUBLIC_API = axios.create({
    baseURL: siteConfig.base_urls.api,
    timeout: 300000,
    headers: {
        "Content-Type": "application/json"
    }
})

export const PUBLIC_FORM_API = axios.create({
    baseURL: siteConfig.base_urls.api,
    timeout: 300000,
    headers: {
        "Content-Type": "multipart/form-data",
    }
})