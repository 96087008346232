export const actions = {
    agent_started: {
        icon:"svg-spinners:3-dots-fade",
        systemText:'agent_started',
        displayText:' '
    },
    consultation_tool: {
        systemText:'consultation_tool',
        displayText:"Setting schedule for consultancy ... ",
        icon:"svg-spinners:3-dots-fade",
    },
    feedback:{
        icon:'',
        systemText:'Feedback',
        displayText:'Feedback'
    },
    topic_ended: {
        icon:"svg-spinners:3-dots-fade",
        systemText:'topic_ended',
        displayText:'Topic Ended'
    },
    calling_supervisor: {
        systemText:'calling_supervisor',
        displayText:"Calling Supervisor ... ",
        icon:"svg-spinners:3-dots-fade",
    },
    booking_appointment:{
        icon:'',
        systemText:'booking_appointment',
        displayText:'Scheduling an appointment...'
    },
    searching_knowledge: {
        icon:"svg-spinners:3-dots-fade",
        systemText:'searching_knowledge',
        displayText:'Searching my knowledge'
    },
    searching: {
        systemText:'searching',
        displayText:"Setting schedule for consultancy ... ",
        icon:"svg-spinners:3-dots-fade",
    },
    searching_web:{
        icon:"svg-spinners:3-dots-fade",
        systemText:'searching_web',
        displayText:'Surfing web ...'
    },
    final_answer:{
        icon:"svg-spinners:3-dots-fade",
        systemText:'final_answer',
        displayText:'Typing'
    },
    nursing_syllabus_retriever:{
        icon:"svg-spinners:3-dots-fade",
        systemText:'nursing_syllabus_retriever',
        displayText:'searching private knowledge'
    },
    suggest_nearby_doctors:{
        icon:"svg-spinners:3-dots-fade",
        systemText:'suggest_nearby_doctors',
        displayText:'searching doctors nearby'
    },
    notebook_tool:{
        icon:"svg-spinners:3-dots-fade",
        systemText:'notebook_tool',
        displayText:'noting down conversation summary'
    },
};

export const USER_ROLES={
    USER:"USER",
    ADMIN:"ADMIN",
    SUPER_ADMIN:"SUPER ADMIN"
}

export const API_PATHS= {
    REFRESH_TOKEN: `token/refresh/`,
    SIGN_IN: `users/login/`,
    SIGN_UP: `users/registration/`,
    FORGOT_PASSWORD: `users/forgot_password/`,
    UPDATE_PROFILE: `users/update/`,
    UPDATE_PHOTO: `users/update/photo`,
    USER_PROFILE: `users/detail/`,
    RESET_PASSWORD: (token) => `reset_password/${token}`,
    CHANGE_PASSWORD: `update_password`,
    SEND_MESSAGE: (chatbotId) => `chatbot/chat/${chatbotId}`,
    SEND_MESSAGE_EMBED: (chatbotId, tokenId) => `widgetChat/${chatbotId}/${tokenId}`,
    ASSIGNEE_LIST : (str=null)=> str?`users/all/?search=${str}`:`users/all/`,
    TDO_LIST: (str=null)=> str? `projects/tdo/all/?search=${str}`:`projects/tdo/all/`,
    CREATE_PROJECT: `projects/create/`,
    UPDATE_PROJECT: `projects/update/`,
    ALL_PROJECTS: (query) => `projects/all/?${query}`,
    BOT_FILE_UPLOAD: (chatbotId) => `chatbots/${chatbotId}/upload`,
    BOT_FILES: (chatbotId) => `chatbots/${chatbotId}/files`,
    BOT_MY_ASSISTANTS: `chatbot/myassistants`,
    FILES_EMBED: (chatbotId) => `chatbots/${chatbotId}/embed`,
    TOOLS: (chatbotId) => `chatbots/${chatbotId}/tools`,
    TOPIC_LIST: (chatbotId, userId) => `topics/${chatbotId}/${userId}`,
    TOPIC_RESOURCE: (topicId) => `topics/${topicId}`,
    TOPIC_CONVO: (topicId) => `topics/${topicId}/messages`,
    DEL_CONVO: (chatbotID) => `topics/chatbot/${chatbotID}/playground/messages`,
    LLM_LIST: `llms`,
    ROADMAPS: `roadmaps`,
    SUPERVISOR: `supervisor-feedback/`,
    DOCTOR_LIST: `doctors/`,
    DOCTOR_CREATE: `doctors/create`,
    CHAMBER_LIST: `chambers/`,
    CHAMBER_CREATE: `chambers/create`,
    BULK_FILE_INPUT: `geo_location/seed_data`
};


export const TOKEN = "access-token"
export const REFRESH_TOKEN = "refresh-token"
export const USER_ID = "user_id"
export const USER_GROUPS = "user_groups"
export const USER_DATA = "user_data"
export const PERMISSIONS = "user_permissions"