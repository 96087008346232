import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';

export default function CustomBackdrop({open=true}) {
    const themeMode=useSelector(state=>state.dom.config.themeMode);
    return (
        <div>
            <Backdrop sx={{ color: themeMode==='dark'?'#fff':'#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}