import React from 'react';
import { Outlet } from 'react-router-dom';

const AllProjects = React.lazy(() => import('../pages/projects/List'));
const WBSBoard = React.lazy(()=> import('../pages/wbs/Board'));

export const CustomerRoutes = [
    {
        title: "Projects",
        element: <Outlet />,
        path: "projects",
        icon: "grommet-icons:projects",
        name: "Projects",
        children: [
            // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
            { path: 'all', icon: "carbon:request-quote", title: "All Projects", element: <AllProjects />, name: "All Projects" },
        ]
    },
    {
        title: "WBS",
        element: <Outlet />,
        path: "wbs",
        icon: "grommet-icons:projects",
        name: "WBS",
        children: [
            // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
            { path: 'board', icon: "carbon:request-quote", title: "All Projects", element: <WBSBoard />, name: "WBS Board" },
        ]
    }
    // customer routes
    // {path: 'how-to', element: <HowTo/>,name:"How To",title:"Guideline"},
    // { path: 'chat-bots', element: <ChatBots />, name: "Expert Assistants", title: "Expert Assistants" },
    // { path: 'chat-bots/request', element: <RequestBot />, name: "requestNewBot", title: "Request New Bot" },

    // { path: 'chat-bots/chat/:chatbotId', element: <ChatBox />, name: "Chat", title: "Chat" },
    // {path: 'my-card/detail', element: <MyCardDetail/>,name:"My NFC Card detail",title:"My NFC Card detail"},
    // {path: 'my-card/apply', element: <ApplyCard/>,name:"Apply NFC Card",title:"Apply for NFC Card"},
    // {path: 'my-card/activate', element: <ActivateCardThreeDigits/>,name:"Activate NFC Card",title:"Activate NFC Card"},
    // {path: 'my-card/add-service', element: <AddService/>,name:"Add Service",title:"Add Service"},
    // {path: 'my-reward', element: <MyReward/>,name:"My Reward",title:"My Reward"}
]