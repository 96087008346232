import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '../../services/axios.service'
import { API_PATHS } from '../../helper/constants'

const initialState = {
    data: {},
    status: 'idle',
    error: ''
}

export const fetchUserDetail = createAsyncThunk('auth/user/details/', async () => {
    const response = await API.get(API_PATHS.USER_PROFILE)
    console.log('myDetails', response)
    return response.data
})

export const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = {}
        },
        setUserData: (state,action)=>{
            state.data=action.payload;
            console.log('user data',action.payload)
        }
    },
    extraReducers: {
        [fetchUserDetail.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchUserDetail.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.data = action.payload.data
        },
        [fetchUserDetail.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetState,setUserData } = authSlice.actions
export default authSlice.reducer
