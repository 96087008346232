// scroll bar
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import CustomBackdrop from './components/backdrop/CustomBackdrop';
import { store, persistedStore } from './store/Store'
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import './i18n';

import './style.css';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <HelmetProvider>
        <BrowserRouter>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistedStore}>
                    <SnackbarProvider maxSnack={3}>
                        <React.Suspense fallback={<CustomBackdrop/>}>
                            <App />
                        </React.Suspense>
                    </SnackbarProvider>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
