// ----------------------------------------------------------------------

export default function FormLabel() {
    return {
        MuiFormLabel: {
            styleOverrides:{
                asterisk: {
                    color: 'red',
                    '&$error': {
                        color: 'red'
                    },
                }
            }
            
        }
    };
}
