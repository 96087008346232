
import {apiUrl, wsUrl} from "./path";


const configs = {
    "DMA": {
        site_logo: {
            "logo": "meet_mates_logo",
            "width": "150px",
            "icon": "meetmates_icon"
        },
        fav_icon: `${process.env.REACT_APP_PUBLIC_URL}/meetmates.png`,
        photo_avatar: "photo_avatar",
        user_avatar: "line-md:account-small", // iconify icon name
        base_urls: {
            api: apiUrl(),
            ws: wsUrl(),
        },
    },
}

export const drawerWidth = 300

export default {
    ...configs[process.env.REACT_APP_COMPANY_NAME],
    site_company: process.env.REACT_APP_COMPANY_NAME,
    site_name: process.env.REACT_APP_COMPANY_NAME,
};