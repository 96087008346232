import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { API } from '../../services/axios.service'

const initialState = {
    data: {},
    preRegisteredUsers:{
        status:'idle',
        error:'',
        data:[]
    },
    status: 'idle',
    error: ''
}

export const fetchPreRegisteredUsersThunk = createAsyncThunk('pre-reg/', async () => {
    const response = await API.get('pre-reg/')
    console.log('pre-reg users', response)
    return response.data
})

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        resetStates: (state) => {
            state.data={};
            state.preRegisteredUsers={data:[],error:'',status:'idle'};
            state.status='idle';
            state.error='';
        },
    },
    extraReducers: {
        [fetchPreRegisteredUsersThunk.pending]: (state) => {
            state.preRegisteredUsers.status = 'loading'
        },
        [fetchPreRegisteredUsersThunk.fulfilled]: (state, action) => {
            state.preRegisteredUsers.status = 'succeeded'
            // Add any fetched posts to the array
            state.preRegisteredUsers.data = action.payload.data
        },
        [fetchPreRegisteredUsersThunk.rejected]: (state, action) => {
            state.preRegisteredUsers.status = 'failed'
            state.preRegisteredUsers.error = action.error.message
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetStates } = usersSlice.actions;
export default usersSlice.reducer;
