import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { TOKEN, USER_ROLES } from './helper/constants';
import { hasGroup } from './helper/helper';
// import MyAssistants from './pages/my-assistans';
// import NotFound from './pages/Page404';
import { AdminRoutes } from './routes/AdminRoutes';
import { CustomerRoutes } from './routes/UserRoutes';

const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'));
const ResetPassword = React.lazy(() => import('./pages/forgot-pass/ResetPassword'));
const PublicLayout = React.lazy(() => import('./layouts/public/PublicLayout'));
const DashboardLayout = React.lazy(() => import('./layouts/dashboard'));
const ComingSoon = React.lazy(() => import('./pages/coming-soon/ComingSoon'));
const ForgetPassword = React.lazy(() => import('./pages/forgot-pass/ForgotPassword'));
const Profile = React.lazy(() => import('./pages/profile/Profile'));
const Login = React.lazy(() => import('./pages/Login'));
const Register = React.lazy(() => import('./pages/Register'));

// ----------------------------------------------------------------------
const baseRedirect = () => {
    let path = '';
    if (process.env.REACT_APP_COMING_SOON === 'true') {
        path = '/coming-soon';
    } else if (localStorage.getItem(TOKEN)) {
        path = '/dashboard/app';
    } else {
        path = '/auth/login';
    }
    return path;
};
export const children = () => {
    let dashboardChildrens = [
        { path: 'app', element: <Dashboard />, name: 'Dashboard', title: 'Dashboard', icon: 'mdi:view-dashboard' },
        { path: 'profile', element: <Profile />, name: 'Profile', title: 'Profile', icon: 'mdi:account' },
    ];
    if (hasGroup(USER_ROLES.SUPER_ADMIN) || (hasGroup(USER_ROLES.USER) && hasGroup(USER_ROLES.ADMIN))) {
        dashboardChildrens = Array.from(dashboardChildrens).concat(CustomerRoutes).concat(AdminRoutes);
    } else if (hasGroup(USER_ROLES.USER)) {
        dashboardChildrens = Array.from(dashboardChildrens).concat(CustomerRoutes);
    } else if (hasGroup(USER_ROLES.ADMIN)) {
        dashboardChildrens = Array.from(dashboardChildrens).concat(AdminRoutes);
    }
    return dashboardChildrens;
};
export default function Router() {
    // const isAuthenticated = localStorage.getItem(TOKEN)?true:false
    return useRoutes([
        {
            path: '/',
            // element: <LogoOnlyLayout />,
            element: <Navigate to={baseRedirect()} />,
            // children: [
            //   { path: '/', element: <Navigate to={localStorage.getItem(TOKEN) ? "/dashboard/app" : "/login"}/> },
            //   { path: '404', element: <NotFound /> },
            //   { path: '*', element: <Navigate to="/404" /> },
            // ],
        },
        {
            path: 'auth',
            element: <PublicLayout />,
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    element: <Login />,
                },
                {
                    path: 'register',
                    name: 'Register',
                    element: <Register />,
                },
                {
                    path: 'forget-password',
                    name: 'Forgot Password',
                    element: <ForgetPassword />,
                },
                {
                    path: 'reset-password',
                    name: 'Reset Password',
                    element: <ResetPassword />,
                },
            ],
        },
        {
            path: 'dashboard',
            element: <DashboardLayout />,
            children: [...children()],
        },
        {
            path: 'coming-soon',
            element: <ComingSoon />,
        },
        {
            path: '*',
            element: <Navigate to="/" />,
        },
    ]);
}
